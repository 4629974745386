:root {
    --toastify-color-info: #39afd1 !important;
    --toastify-color-success: #0acf97 !important;
    --toastify-color-warning: #ffbc00 !important;
    --toastify-color-error: #fa5c7c !important;
}

.page-loading-loader-div {
    height: calc(100vh - 650px);
}

.table-hover > tbody :hover{
    cursor: pointer;
}

.table-button :hover{
    transition: 0.15s;
    color: #3E4348;
}

.popover-comment:hover{
    cursor: pointer;
}

.batch-report-table:hover{
    cursor: pointer;
}

.batch-report-table .reset-expansion-style:hover{
    cursor: initial;
}

.batch-report-employee-card-header:hover{
    cursor: pointer !important;
}

.link-button{
    opacity: 0.75;
}

.link-button:hover{
    transition: 0.2s;
    opacity: 1;
}

.no-data:hover{
    pointer-events: none;
}

.col-truncate{
    display: inline-block;
    overflow: hidden;
    white-space: nowrap; 
    text-overflow:ellipsis; 
}

.upload-result-toast{
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    background-color: #ffff;
    text-align: center;
}